import type * as api from '@affixapi/api';
import * as utils from '@affixapi/utils';

// hr-payroll
import bamboohr from '@logos/providers/hr-payroll/bamboohr.png';
import brainpayroll from '@logos/providers/hr-payroll/brain-payroll.svg';
import breathe from '@logos/providers/hr-payroll/breathe.png';
import brightpay from '@logos/providers/hr-payroll/brightpay-connect.png';
import cezanne from '@logos/providers/hr-payroll/cezanne.png';
import deel from '@logos/providers/hr-payroll/deel.svg';
import deputy from '@logos/providers/hr-payroll/deputy.png';
import employmenthero from '@logos/providers/hr-payroll/employment-hero.svg';
import factorialhr from '@logos/providers/hr-payroll/factorialhr.png';
import fourthhr from '@logos/providers/hr-payroll/fourth.png';
import gusto from '@logos/providers/hr-payroll/gusto.png';
import hailyhr from '@logos/providers/hr-payroll/hailyhr.png';
import hibob from '@logos/providers/hr-payroll/hibob.svg';
import humaans from '@logos/providers/hr-payroll/humaans.png';
import iriscascade from '@logos/providers/hr-payroll/iris-cascade.svg';
import itrent from '@logos/providers/hr-payroll/itrent.svg';
import justworks from '@logos/providers/hr-payroll/justworks.png';
import moorepay from '@logos/providers/hr-payroll/moorepay.png';
import onpay from '@logos/providers/hr-payroll/onpay.svg';
import nmbrs from '@logos/providers/hr-payroll/nmbrs.png';
import oysterhr from '@logos/providers/hr-payroll/oysterhr.png';
import parolla from '@logos/providers/hr-payroll/parolla.png';
import paycircle from '@logos/providers/hr-payroll/paycircle.svg';
import payfit from '@logos/providers/hr-payroll/payfit.svg';
import pento from '@logos/providers/hr-payroll/pento.png';
import peoplehr from '@logos/providers/hr-payroll/peoplehr.svg';
import personio from '@logos/providers/hr-payroll/personio.svg';
import planday from '@logos/providers/hr-payroll/planday.png';
import qbo from '@logos/providers/hr-payroll/qbo.svg';
import rippling from '@logos/providers/hr-payroll/rippling.svg';
import remote from '@logos/providers/hr-payroll/remote.com.svg';
import sagehr from '@logos/providers/hr-payroll/sagehr.jpg';
import sageone from '@logos/providers/hr-payroll/sageone.svg';
import sapling from '@logos/providers/hr-payroll/sapling.png';
import simplePayIe from '@logos/providers/hr-payroll/simplepay.ie.png';
import staffology from '@logos/providers/hr-payroll/staffology.png';
import xero from '@logos/providers/hr-payroll/xero.png';

// shared
import sandboxLogo from '@logos/providers/sandbox.svg';

const iconSelector = (k: api.root.Provider) => {
  const result = ((k: api.root.Provider) => {
    switch (k) {
      // official
      case 'brain payroll':
        return brainpayroll;
      case 'breathe':
        return breathe;
      case 'bamboohr':
        return bamboohr;
      case 'cezanne':
        return cezanne;
      case 'deel':
        return deel;
      case 'iris cascade':
        return iriscascade;
      case 'hailyhr':
        return hailyhr;
      case 'hibob':
        return hibob;
      case 'humaans':
        return humaans;
      case 'moorepay':
        return moorepay;
      case 'nmbrs':
        return nmbrs;
      case 'personio.de':
        return personio;
      case 'sagehr':
        return sagehr;
      case 'saplinghr':
        return sapling;
      case 'staffology':
        return staffology;
      case 'xero uk':
        return xero;
      case 'paycircle':
        return paycircle;
      case 'payfit':
        return payfit;

      // xhr
      case 'brightpay connect':
        return brightpay;
      case 'factorialhr':
        return factorialhr;
      case 'gusto':
        return gusto;
      case 'deputy':
        return deputy;
      case 'employment hero':
        return employmenthero;
      case 'fourthhr':
        return fourthhr;
      case 'justworks':
        return justworks;
      case 'itrent':
        return itrent;
      case 'simplepay.ie':
        return simplePayIe;
      case 'sageone':
        return sageone;
      case 'oysterhr':
        return oysterhr;
      case 'peoplehr':
        return peoplehr;
      case 'remote.com':
        return remote;
      case 'quickbooks online':
        return qbo;
      case 'onpay':
        return onpay;
      case 'rippling':
        return rippling;
      case 'parolla.ie':
        return parolla;
      case 'pento.io':
        return pento;
      case 'planday':
        return planday;

      case 'sandbox':
        return sandboxLogo;
      default:
        return sandboxLogo; // fallback, but let's fill in a default
    }
  })(k);

  return result;
};

export const providers: {
  [k in api.root.Mode]?: utils.sharedTypes.connect.ProviderConfigWithLogo[];
} = {
  official: utils.connect.providers.providers['official'].map(
    (providerConfig) => ({
      ...providerConfig,
      logo: iconSelector(providerConfig.id),
    })
  ),

  xhr: utils.connect.providers.providers['xhr'].map((providerConfig) => ({
    ...providerConfig,
    logo: iconSelector(providerConfig.id),
  })),
};

export const providersFor = ({
  mode,
  opts,
}: {
  mode: api.root.Mode;
  opts?: {
    clientId?: string;
    sandbox?: boolean;
  };
}): utils.sharedTypes.connect.ProviderConfigWithLogo[] => {
  const providersForModeOrUndefined = providers[mode];
  if (!providersForModeOrUndefined) throw new Error('no providers!');
  let providersForMode = providersForModeOrUndefined; // to just set the types correctly

  if (opts?.clientId) {
    const clientId = opts.clientId;

    // don't display providers for clientIds that are blocked
    providersForMode = providersForMode.filter(
      (provider) => !provider.clientIds?.block?.includes(clientId)
    );

    // certain providers are beta and thus whitelist their appearance
    providersForMode = providersForMode.filter(
      (provider) =>
        !provider.clientIds?.allow ||
        provider.clientIds.allow.length === 0 ||
        provider.clientIds?.allow?.includes(clientId)
    );
  }

  if (opts?.sandbox)
    return providersForMode.filter(
      (provider) =>
        (provider?.sandbox || provider.sandbox !== undefined) && // `provider.sandbox !== undefined` so the types flow through
        opts.sandbox === provider.sandbox
    );

  // remove sandbox from the list
  return providersForMode.filter(({ sandbox }) => !sandbox ?? false);
};
