import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _trim from 'lodash/trim';
import * as utils from '@affixapi/utils';
import type * as api from '@affixapi/api';

import { FiX } from 'react-icons/fi';
import styles from '@sass/Authorize.module.scss';
import { stepMap } from '@lib/steps';
import { validate } from '@services/validate';
import { withAuthorizeContext } from '@pages/AuthorizeContext';
import {
  paramAppType,
  paramClientId,
  paramMode,
  paramScopes,
  paramRedirectUri,
  paramProvider,
  paramSandbox,
  paramState,
  paramAddAdmin,
  paramTags,
  paramEmail,
} from '@lib/params';
import { providersFor } from '@lib/providers';
import { PageContext } from '@lib/types';

const Authorize = ({
  appType,
  completeAuth,
  currentStep,
  setAppType,
  setClient,
  setMode,
  setProvider,
  setRedirectUri,
  setSandbox,
  setState,
  setAddAdmin,
  setTags,
  setEmail,
  validateAndSetScopes,
}: PageContext): JSX.Element => {
  const { search } = useLocation();

  const [error, setError] = useState<string | null | undefined>(null);

  useEffect((): void => {
    const paramValidation = async () => {
      const query = new URLSearchParams(search);

      const queryGetAndTrim = (key: string) =>
        _trim(query.get(key) ?? undefined);

      const clientId = queryGetAndTrim(paramClientId);
      const redirectUri = queryGetAndTrim(paramRedirectUri);
      const scopes = queryGetAndTrim(paramScopes);
      const appType = query.get(paramAppType);
      const mode = queryGetAndTrim(paramMode); // don't set as api.Mode as we haven't validated;
      const provider = queryGetAndTrim(paramProvider);
      const sandbox = queryGetAndTrim(paramSandbox);
      const state = query.get(paramState);
      const addAdmin = queryGetAndTrim(paramAddAdmin);
      const tags = queryGetAndTrim(paramTags);
      const email = queryGetAndTrim(paramEmail);

      try {
        if (mode) {
          const validatedMode = utils.connect.validate.mode({
            isBackend: false,
            mode,
          });
          setMode(validatedMode);
        }

        // CONSIDER ""validating"" tags
        if (tags && tags.length) setTags(tags.split(' '));

        if (email) setEmail(email);

        if (['true', 'True', '1'].includes(addAdmin)) setAddAdmin(true);
        if (['false', 'False', '0'].includes(addAdmin)) setAddAdmin(false);

        if (provider) {
          const providersForClient = providersFor({
            mode: mode as api.root.Mode,
            opts: {
              clientId,
              sandbox: Boolean(sandbox), // since we are selecting by provider, we want to include all adapters!
            },
          });

          const validProvider = utils.connect.validate.provider({
            isBackend: false,
            provider,
            providers: providersForClient,
          }) as utils.sharedTypes.connect.ProviderConfigWithLogo;

          setProvider(validProvider);
        }

        if (appType) {
          const validatedAppType = utils.connect.validate.appType({
            isBackend: false,
            type: appType,
          }) as 'spa' | 'ssr';

          setAppType(validatedAppType);
        }

        if (['true', 'True', '1'].includes(sandbox)) setSandbox(true);

        setRedirectUri(redirectUri);
        setState(state);

        const scopesWrapped = validateAndSetScopes({
          mode: mode as api.root.Mode, // has been validated above
          scopes,
        });

        const client = await validate({
          clientId,
          mode: mode as api.root.Mode,
          redirectUri,
          scopes: scopesWrapped,
        });

        setClient(client);
      } catch (err) {
        if (err instanceof Error) {
          if (appType === 'spa') {
            completeAuth({ error: err.message, forceSpa: true });
          } else {
            setError(err.message);
          }
        }
      }
    };

    // https://stackoverflow.com/questions/57238928/react-typescript-16-8-how-to-make-useeffect-async
    // this will load the initial page, but throw an error if the client is
    // invalid or the redirectUri is not correct
    // it is ok that this promise is not awaited; it allows for the page to
    // load before the call to the backend api is returned
    paramValidation(); // eslint-disable-line @typescript-eslint/no-floating-promises
  }, []);

  const onClose = (): void => {
    if (appType === 'spa') completeAuth({ closed: true });
  };

  return (
    <div className={styles.outerContainer} onClick={onClose}>
      {error ? (
        <div data-testid="error" className={styles.error}>
          {error}
        </div>
      ) : (
        <div
          className={styles.container}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {appType === 'spa' && (
            <FiX
              className={styles.closeIcon}
              onClick={onClose}
              aria-label="Close"
            />
          )}

          {stepMap[currentStep](
            {} as utils.typeUtils.DeepNonNullable<PageContext>
          )}
        </div>
      )}
    </div>
  );
};

export default withAuthorizeContext(Authorize);
