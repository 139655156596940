import * as api from '@affixapi/api';

import {
  CezanneClientId,
  DeelClientId,
  EmploymentHeroClientId,
  Env,
  OysterOAuthSlug,
  PayfitClientId,
  XeroUKClientId,
} from '@lib/types';

export const getLocalValue = <environmentVariables>(
  name: environmentVariables
): string | undefined => {
  const value = process.env[String(name)]; // https://stackoverflow.com/a/46043752

  if (value) return value;

  return undefined;
};

export const env = getLocalValue<Env>('REACT_APP_ENV');

export const cezanneClientId = getLocalValue<CezanneClientId>(
  'REACT_APP_CEZANNE_CLIENT_ID'
);
export const deelClientId = getLocalValue<DeelClientId>(
  'REACT_APP_DEEL_CLIENT_ID'
);
export const employmentHeroClientId = getLocalValue<EmploymentHeroClientId>(
  'REACT_APP_EMPLOYMENT_HERO_CLIENT_ID'
);
export const oysterOAuthSlug = getLocalValue<OysterOAuthSlug>(
  'REACT_APP_OYSTER_OAUTH_SLUG'
);
export const payfitClientId = getLocalValue<PayfitClientId>(
  'REACT_APP_PAYFIT_CLIENT_ID'
);
export const xeroUKClientId = getLocalValue<XeroUKClientId>(
  'REACT_APP_XERO_UK_CLIENT_ID'
);

// https://developer.deel.com/docs/scopes-1
const deelScopes = [
  'contracts:read',
  'contracts:write',
  'organizations:read',
  'timesheets:read',
  'off-cycle-payments:read',
  'Users:read',
  'Users:write',
  'candidates:read',
  'candidates:write',
  'people:read',
  'profile:read',
  'accounting:read',
];

const deelScopeMap: {
  [key in api.root.Scope]: typeof deelScopes | null;
} = {
  '/2023-03-01/official/company': ['organizations:read'],
  '/2023-03-01/official/employee': [
    'contracts:write',
    'Users:write',
    'people:write',
  ],
  '/2023-03-01/official/employees': [
    'contracts:read',
    'Users:read',
    'people:read',
  ],
  '/2023-03-01/official/groups': ['Users:read', 'people:read'],
  '/2023-03-01/official/identity': ['profile:read'],
  '/2023-03-01/official/time-off-balances': null,
  '/2023-03-01/official/time-off-entries': null,
  '/2023-03-01/official/timesheets': ['timesheets:read'],
  '/2023-03-01/official/work-locations': ['contracts:read'],
  '/2023-03-01/xhr/company': ['organizations:read'],
  '/2023-03-01/xhr/employee': [
    'contracts:write',
    'Users:write',
    'people:write',
  ],

  '/2023-03-01/xhr/employees': ['contracts:read', 'Users:read', 'people:read'],
  '/2023-03-01/xhr/groups': ['Users:read', 'people:read'],
  '/2023-03-01/xhr/identity': ['profile:read'],
  '/2023-03-01/xhr/payruns': ['off-cycle-payments:read'],
  '/2023-03-01/xhr/payruns/:payrun_id': ['off-cycle-payments:read'],
  '/2023-03-01/xhr/time-off-balances': null,
  '/2023-03-01/xhr/time-off-entries': null,
  '/2023-03-01/xhr/timesheets': ['timesheets:read'],
  '/2023-03-01/xhr/work-locations': ['contracts:read'],
};

export const deelScopesToRequest = ({
  scopes,
}: {
  scopes: api.root.Scopes;
}): typeof deelScopes =>
  scopes
    .reduce(
      (acc, scope) => [...acc, deelScopeMap[scope]],
      // [] as (string | null)[] // why doesn't this type work ?
      // [] as ((typeof deelScopes)[number] | null)[] // why doesn't this type work ?
      [] as any[] // eslint-disable-line @typescript-eslint/no-explicit-any
    )
    .filter((x) => x)
    .flat();
