import * as api from '@affixapi/api';

import { default as Preamble } from '@pages/Preamble';
import { default as Select } from '@pages/Select';
import { default as SignIn } from '@pages/SignIn';
import { default as Mfa } from '@pages/Mfa';
import { default as CHOOSE } from '@pages/Choose';
import { default as Scopes } from '@pages/Scopes';

// these load in the pages/functions for the respective step
// these should not be moved to @affixapi/api
export const stepMap = {
  [api.consts.stepMap.PREAMBLE]: Preamble,
  [api.consts.stepMap.SCOPES]: Scopes,
  [api.consts.stepMap.SELECT]: Select,
  [api.consts.stepMap.SIGN_IN]: SignIn,
  [api.consts.stepMap.MFA]: Mfa,
  [api.consts.stepMap.CHOOSE]: CHOOSE,
} as const;

export const nextStepMap = {
  [api.consts.stepMap.PREAMBLE]: api.consts.stepMap.SCOPES,
  [api.consts.stepMap.SCOPES]: api.consts.stepMap.SELECT,
  [api.consts.stepMap.SELECT]: api.consts.stepMap.SIGN_IN,
  [api.consts.stepMap.SIGN_IN]: api.consts.stepMap.MFA,
  // [api.consts.stepMap.SIGN_IN]: api.consts.stepMap.CHOOSE, // needed ??
  [api.consts.stepMap.MFA]: api.consts.stepMap.CHOOSE, // needed ??
  // [api.consts.stepMap.MFA]: null, // complete
} as const;

export const prevStepMap = {
  // [step.PREAMBLE]: null, // start
  [api.consts.stepMap.SCOPES]: api.consts.stepMap.PREAMBLE,
  [api.consts.stepMap.SELECT]: api.consts.stepMap.SCOPES,
  [api.consts.stepMap.SIGN_IN]: api.consts.stepMap.SELECT,
  [api.consts.stepMap.MFA]: api.consts.stepMap.SIGN_IN,
  [api.consts.stepMap.CHOOSE]: api.consts.stepMap.SIGN_IN,
} as const;
