import React from 'react';

import { FiSmile, FiEyeOff, FiShield } from 'react-icons/fi';
import { companyName as utilsCompanyName } from '@affixapi/utils/shared-consts';
import * as utils from '@affixapi/utils';
import styles from '@sass/Preamble.module.scss';
import wordLogo from '@logos/wordmark.svg';
import sharedStyles from '@sass/shared.module.scss';
import { withAuthorizeContext } from '@pages/AuthorizeContext';
import { PageContext } from '@lib/types';
import { env } from '@lib/utils';

// `affix-api` => `Affix API`
const companyName = utils.sharedFns.str
  .properCase(utilsCompanyName.replaceAll('-', ' '))
  .replaceAll('pi', 'PI');

export const Preamble = ({
  client,
  nextStep,
}: Pick<PageContext, 'client' | 'nextStep'>): JSX.Element => {
  const { name } = client;

  console.log(`environment is: ${env}`);

  return (
    <div className={sharedStyles.container}>
      <div className={sharedStyles.header}>
        <div className={sharedStyles.logos}>
          <img src={wordLogo} alt="affixapi logo" />
        </div>
        <div className={sharedStyles.title}>
          {name} uses <b>{companyName}</b> to connect your HR/payroll software.
        </div>

        <div className={sharedStyles.seperator} />

        <div className={styles.bulletGroup}>
          <div className={styles.iconGroup}>
            <FiSmile />
          </div>
          <div className={styles.textGroup}>
            <div className={styles.textHeader}>Connect effortlessly</div>
            <div className={styles.subheader}>
              <b>{companyName}</b> lets you securely connect your account in
              seconds. Your credentials are not shared with {name}.
            </div>
          </div>
        </div>
        <div className={styles.bulletGroup}>
          <div className={styles.iconGroup}>
            <FiShield />
          </div>
          <div className={styles.textGroup}>
            <div className={styles.textHeader}>
              {companyName} takes data security and privacy very seriously
            </div>
            <div className={styles.subheader}>
              You can review a list of scopes before granting {name} access to
              your account. If you don&apos;t want to share this, you don&apos;t
              have to, and you can close this screen. You can view more about{' '}
              <b>{companyName}</b>'s security{' '}
              <a
                href="https://affixapi.com/security"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </div>
          </div>
        </div>
        <div className={styles.bulletGroup}>
          <div className={styles.iconGroup}>
            <FiEyeOff />
          </div>
          <div className={styles.textGroup}>
            <div className={styles.textHeader}>Your data belongs to you</div>
            <div className={styles.subheader}>
              <b>{companyName}</b> does not sell any of your data, and will only
              use it with your permission. After signing in, your data is only
              shared with {name}. <b>{companyName}</b> may store your
              credentials, cookies, and/or api-keys are to enable the sharing of
              your data data with {name}. You authorise <b>{companyName}</b> to
              access your data and share it with {name}.
            </div>
          </div>
        </div>
      </div>

      <div className={sharedStyles.seperator} />

      <div className={sharedStyles.footer}>
        <div className={styles.privacyPrompt}>
          By selecting &quot;Continue&quot; and/or using the Service, you and/or
          End Customer acknowledges and agrees to the{' '}
          <b>
            <a
              href="https://affixapi.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {companyName} End User Privacy Policy
            </a>
          </b>{' '}
          and{' '}
          <b>
            <a
              href="https://affixapi.com/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              {companyName} Terms of Service
            </a>
          </b>
          .
        </div>
        <button
          className={sharedStyles.button}
          type="submit"
          onClick={() => nextStep()}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default withAuthorizeContext(Preamble);
