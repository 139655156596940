import axios from 'axios';
import type { AxiosError } from 'axios';
import _chain from 'lodash/chain';
import type * as api from '@affixapi/api';
import * as utils from '@affixapi/utils';

import { PageContextState } from '@lib/types';
import * as shared from '@services/shared';

const useLive = shared.useLive;
const apiVersion = shared.apiVersion;
const apiUrl = useLive ? `${shared.apiUrl}/${apiVersion}` : shared.apiUrl;

export const validate = async ({
  clientId,
  redirectUri,
  scopes,
  mode,
}: {
  clientId: api.v20230301.ValidateRequest['client_id']; // can't pass in `client` here, as you get `client` from this call
  mode: NonNullable<PageContextState['mode']>;
  redirectUri: NonNullable<PageContextState['redirectUri']>;
  scopes: NonNullable<PageContextState['scopes']>;
} & Pick<
  PageContextState,
  'redirectUri'
>): Promise<api.v20230301.ValidateResponse> => {
  const isBackend = false;

  utils.connect.validate.mode({ isBackend, mode });
  utils.connect.validate.clientId({ clientId, isBackend });
  utils.connect.validate.redirectUri({ isBackend, redirectUri });

  const validatedScopes = utils.connect.validate.scopes({
    isBackend,
    mode,
    scopes,
  });
  const requestBody: api.v20230301.ValidateRequest = {
    client_id: clientId,
    mode,
    redirect_uri: redirectUri,
    scopes: validatedScopes,
  };

  if (!useLive)
    return {
      client_id: clientId,
      name: "John's Test Application",
      redirect_uri: 'https://affixapi.com',
    };

  try {
    const { data } = await axios.request({
      data: requestBody,
      method: 'POST',
      url: `${apiUrl}/connect/validate`,
    });

    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.isAxiosError) {
      const axiosErr = err as AxiosError;
      const { response: axiosErrPayload } = axiosErr;

      const errMessage = _chain(axiosErrPayload)
        .get('data')
        // @ts-expect-error: lodash's types are messed up here: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35400
        .get('error', 'An unexpected error occurred, try again!')
        .value() as string;
      console.log({ errMessage });

      throw new Error(`${errMessage}. invalid client_id or redirect_uri?`);
    }
    throw err;
  }
};
